<!-- =========================================================================================
    File Name: token error.vue
    Description: erreur de token lié a l'api
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img logoutSeesion">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">
            <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">Session expirée</h1>
            <div class="container">
                <h3>1) Vous avez connecté ce compte sur un autre périphérique !</h3><br/>
                <h3>2) Vous êtes resté trop longtemps inactif !</h3><br/>
                <br/>
            </div>
            <vs-button size="large" to="/">Retour page connexion</vs-button>
            {{ logout() }}
        </div>
    </div>
</template>


<style lang="scss">
.logoutSeesion{
    text-align:left;
    h3{
        text-align:left;
    }
}
</style>


<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        logout:function()
        {
            this.$srvApi.logout(false);
            this.$notify.success('Deconnexion réussie');
            return '';
        }
    }
};

</script>